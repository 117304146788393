<template>
	<div>
		<div class="nodata" v-if="lists.count == 0"><img src="../assets/img/nofollow.png" alt="" /></div>
		<div class="follow" v-else>
			<div class="follow-item" v-for="(item, index) in lists.list" :key="index">
				<div class="info">
					<img :src="item.face" class="avatar" @error="onAvatarError" />
					<span class="nickname">{{ item.nickname }}</span>
					<span :class="['button', item.is_follow == 1 ? '' : 'active']" @click="onFollow(item)">{{ item.is_follow == 1 ? $t('unFollow') : $t('onfollow') }}</span>
				</div>

				<div class="live" v-if="item.live.length == 0">
					<div class="live-item"><img class="cover" src="../assets/img/nolive.png" @error="onCoverError" /></div>
				</div>
				<div class="live" v-else>
					<router-link :to="v.href" class="live-item" v-for="v in item.live" :key="v.tournament_id">
						<img class="cover" :src="v.cover" @error="onCoverError" />
						<div class="title">{{ v.title }}</div>
						<div class="living">
							<img src="../assets/img/living.gif" />
                {{ $t('streaming') }}
						</div>
					</router-link>
				</div>
			</div>
			<a-pagination class="pagination" :current="lists.page" :total="lists.count" :page-size="10" @change="onPageChange" />
		</div>
	</div>
</template>

<script>
import { follow } from '@/utils/fn';
export default {
	name: 'UserFollow',
	data() {
		return {
			lists: {
				page: 1,
				count: 0,
				list: [],
				next: 0
			}
		};
	},
	mounted() {
		this.getFollows();
	},
	methods: {
		async getFollows() {
			const response = await this.$api.get(`/web/follow_anchor_list?page=${this.lists.page}`);
			if (response.code == 200) {
				response.data.list.forEach(item => {
					item.live = (item.live || []).slice(0, 4).map(this.makeMatchItem);
					item.is_follow = 1;
				});

				Object.assign(this.lists, response.data);
			}
		},
		async onPageChange(page) {
			this.lists.page = page;
			this.getFollows();
		},
		async onFollow(item) {
			if (item.is_follow == 1) {
				this.$modal.confirm({
            title: this.$t('unFollowReminder'),
			  		okType: 'danger',
            okText: this.$t('unFollow'),
            cancelText: this.$t('thinkAgain'),
			  		onOk: () => {
					  	follow(item);
					}
				});
			} else {
				follow(item);
			}
		}
	}
};
</script>

<style lang="less">
@import url('~@/assets/less/variables.less');
.follow {
	&-item {
		border-bottom: #eef3f8 1px solid;
		.info {
			height: 80px;
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			
			.avatar {
				display: block;
				width: 48px;
				height: 48px;
				border: #ed4b65 1px solid;
				border-radius: 50%;
				overflow: hidden;
			}
			.nickname {
				flex: 1;
				margin-right: 15px;
				font-size: 16px;
				color: #26293a;
				white-space: nowrap;
				text-align: right;
			}
			.button {
				font-size: 14px;
				padding:0 10px;
				height: 32px;
				color: #cacaca;
				border: 2px solid #cacaca;
				border-radius: 16px;
				background: transparent;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;
				// background: @active-bgcolor;

				&.active {
					color: #ffffff;
					border: 0;
					background: @active-bgcolor;
				}
			}
		}
		.live {
			padding-bottom: 16px;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			flex-direction: row-reverse;
			&-item {
				position: relative;
				width: 186px;
				height: 120px;
				border-radius: 8px;
				overflow: hidden;
				margin-right: 20px;
				.cover {
					width: 100%;
					height: 100%;
					display: block;
					object-fit: cover;
				}
				.living {
					position: absolute;
					top: 22px;
					left: 0;
					width: 78px;
					height: 22px;
					background: #ffdd00;
					border-radius: 0px 11px 11px 0px;
					font-size: 12px;
					color: #433c12;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: row-reverse;

					img {
						margin-left: 5px;
						width: 10px;
					}
				}
				.title {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					padding: 0 8px;
					height: 24px;
					line-height: 24px;
					color: #ffffff;
					font-size: 12px;
					background-color: rgba(0, 0, 0, 0.8);
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					box-sizing: border-box;
					direction: rtl;
				}
			}
		}
	}

	.pagination {
		margin-top: 20px;
	}
}
</style>
